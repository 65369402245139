<template>
  <CCard>
    <CCardBody>
      <TTabs :tabs="tabs" @change-tab="updateActiveTab" :activeTab="activeTab">
        <template v-for="tab in tabs" #[tab.key]>
          <UserPrice :key="tab.key" :price_type="tab.key" />
        </template>
      </TTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from "vuex";
import UserPrice from "./UserPrice.vue";

export default {
  components: { UserPrice },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          key: "buying-agent-fee",
          name: "Purchase surcharge",
          icon: "cisLibrary",
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      freight_charge_types: "warehouse.freight_charge_types.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    updateActiveTab(t) {
      this.activeTab = t;
    },
  },
};
</script>
