<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap align-items-center">
      <TMessage :content="user.id" bold noTranslate class="ml-2" />
      <TSpinner :loading="loading" />
      <div class="ml-auto">
        <SButtonResetPassword :email="user.email" class="ml-auto" />
      </div>
    </CCardHeader>
    <CCardBody class="d-flex flex-wrap">
      <CCol col="12" lg="12" xl="12">
        <CRow>
          <CCol :lg="{ size: 4, order: 1 }" md="6" sm="12" class="my-2">
            <TMessage content="Name" color="primary" bold>
              <template #prefix>
                <CIcon name="cilUser" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="user.name"
              class="mt-2"
              :editable="!loading"
              @change="
                $store.dispatch('auth.users.detail.update', {
                  name: $event,
                })
              "
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 2 }" md="6" sm="12" class="my-2">
            <TMessage content="Email" color="primary" bold>
              <template #prefix>
                <CIcon name="cilMail" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="user.email"
              class="mt-2"
              :editable="!loading"
              @change="
                $store.dispatch('auth.users.detail.update', {
                  email: $event,
                })
              "
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 4 }" md="6" sm="12" class="my-2">
            <TMessage content="Tel" color="primary" bold>
              <template #prefix>
                <CIcon name="cilPhone" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="user.tel"
              class="mt-2"
              :editable="!loading"
              @change="
                $store.dispatch('auth.users.detail.update', {
                  tel: $event,
                })
              "
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 5 }" md="6" sm="12" class="my-2">
            <TMessage content="Role" color="primary" bold>
              <template #prefix>
                <CIcon name="cilPeople" class="my-auto mr-2" />
              </template>
            </TMessage>
            <SMessageRole
              v-if="user.role"
              :editable="!loading"
              :id="user.role_id"
              :content="user.role.name"
              class="mt-2"
              @change="
                $store
                  .dispatch('auth.users.detail.update', {
                    role_id: $event,
                  })
                  .then(() => {
                    $router.push({ path: user.id });
                  })
              "
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 5 }" md="6" sm="12" class="my-2">
            <TMessage content="Organization" color="primary" bold>
              <template #prefix>
                <CIcon name="cilTimeline" class="my-auto mr-2" />
              </template>
            </TMessage>
            <SMessageOrganization
              :editable="!loading"
              :id="user.organization_id"
              class="mt-2"
              @change="
                $store.dispatch('auth.users.detail.update', {
                  organization_id: $event,
                })
              "
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 3 }" md="6" sm="12" class="my-2">
            <TMessage content="Created at" color="primary" bold>
              <template #prefix>
                <CIcon name="cilCalendar" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageDateTime :content="user.created_at" class="mt-2" />
          </CCol>
          <CCol :lg="{ size: 4, order: 6 }" md="6" sm="12" class="my-2">
            <TMessage content="Latest update" color="primary" bold>
              <template #prefix>
                <CIcon name="cilCalendarCheck" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageDateTime :content="user.updated_at" class="mt-2" />
          </CCol>
        </CRow>
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    if (this.id) {
      this.$store.dispatch("auth.users.detail.fetch", this.id);
    }
  },
  computed: {
    ...mapGetters({
      user: "auth.users.detail",
      loading: "auth.users.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
