<template>
  <TModal
    title="Create prices"
    :show.sync="show"
    size="lg"
    :fetching="loading"
    @click-create="onCreatePrice"
  >
    <TInputText
      :placeholder="$t('Name')"
      @keyup.enter.native="onCreatePrice"
      :value.sync="input"
    />
  </TModal>
</template>

<script>
export default {
  expose: ["showModal"],
  data() {
    return {
      input: "",
      show: false,
      type: "shipping-fee",
      default_conditions: [],
    };
  },
  watch: {
    show() {
      this.input = "";
    },
  },
  computed: {
    loading() {
      return this.$store.getters[`helper.prices.creating`];
    },
  },
  methods: {
    onCreatePrice() {
      this.$store
        .dispatch("helper.prices.create", {
          name: this.input,
        })
        .then(({ id }) => {
          let type_creating = [
            { key: "type", value: this.type },
            ...this.default_conditions,
          ];

          return Promise.all(
            type_creating.map((t) =>
              this.$tomoni.helper.conditions.create({
                price_id: id,
                ...t,
              })
            )
          );
        })
        .then(() => {
          this.show = false;
          this.$emit("success");
        });
    },
    showModal(t = "shipping-fee", d = []) {
      this.show = true;
      this.type = t;
      this.default_conditions = d;
    },
  },
};
</script>
