<template>
  <TTabs
    :tabs="tabs"
    @change-tab="$router.push({ query: { tab: $event } })"
    :activeTab="$route.query.tab"
  >
    <template #user>
      <UserInfo />
    </template>
    <template #address>
      <Address />
    </template>
    <template #authorization>
      <Permission />
    </template>
    <template #prices>
      <ListUserPrice />
    </template>
    <template #price-list>
      <UserPriceList />
    </template>
  </TTabs>
</template>
<script>
import Permission from "./components/Permissions.vue";
import UserInfo from "./components/UserInfo.vue";
import Address from "./components/Address.vue";
import ListUserPrice from "./components/ListUserPrice.vue"
import UserPriceList from "./components/UserPriceList.vue";

export default {
  components: { UserInfo, Address, Permission, ListUserPrice, UserPriceList },
  data() {
    return {
      tabs: [
        { key: "user", name: "User info", icon: "cisUser" },
        { key: "address", name: "Address", icon: "cisMap" },
        {
          key: "authorization",
          name: "Authorization",
          icon: "cisPeople",
        },
        {
          key: "prices",
          name: "Shipping fee",
          icon: "cisTruck",
        },
        {
          key: "price-list",
          name: "Price list",
          icon: "cisListRich",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    // Lấy dữ liệu dùng cho các select bên trong table (SModalPriceCondition)
    this.$store.dispatch("accounting.currencies.fetch.if-first-time");
    this.$store.dispatch("warehouse.shipment_methods.fetch.if-first-time");
    this.$store.dispatch("warehouse.areas.fetch.if-first-time");
    this.$store.dispatch("warehouse.freight_charge_types.fetch.if-first-time");
    this.$store.dispatch("helper.prices.apply-query", {
        "conditions[user]": this.id,
      });
  },
};
</script>
