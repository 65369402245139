<template>
  <div>
    <TablePrice :data_key="price_type" :prices="data_table" @update:showModalCreatePrice="onShowModalCreatePrice()" />

    <ModalCreatePrice ref="modalCreatePrice" @success="getUserPrice" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TablePrice from "../../components/TablePrice.vue";
import ModalCreatePrice from "../../components/ModalCreatePrice.vue";

export default {
  components: { TablePrice, ModalCreatePrice },
  props: {
    unit: {
      type: String,
      default: () => "weight",
    },
    price_type: {
      type: String,
      default: () => "shipping-fee",
    },
  },
  computed: {
    ...mapGetters({
      prices: "helper.prices.list",
      loading: "helper.prices.loading",
    }),
    id() {
      return this.$route.params.id;
    },
    data_table() {
      if (this.price_type == "shipping-fee")
        return this.prices.filter((i) => {
          let unit_condition_value = i.conditions?.find(
            (i) => i.key === "unit"
          )?.value;

          return unit_condition_value === this.unit;
        }) || []

      else {
        return this.prices.filter((i) => {
          let unit_condition_value = i.conditions?.find(
            (i) => i.value === this.price_type
          )
          return unit_condition_value
        })
      }
    }
  },
  methods: {
    getUserPrice() {
      this.$store.dispatch("helper.prices.apply-query", {
        "conditions[user]": this.id,
      });
    },
    onShowModalCreatePrice() {
      this.$refs.modalCreatePrice.showModal(this.price_type,
        this.price_type == "shipping-fee" ? [
          { key: "user", value: this.id },
          { key: "unit", value: this.unit },
        ] : [
          { key: "user", value: this.id },
        ])
    },
  },
};
</script>
