<template>
  <div>
    <TTableAdvance
      :items="data_table"
      :fields="fields"
      creatable
      noFilter
      noPaginate
      @click-create="$emit('update:showModalCreatePrice', true)"
      slugKey="price_id"
      store="helper.prices"
      resource="/admin/goods/prices"
    >
      <template #_="{ item }">
        <td
          v-if="item.price_rowspan > 0"
          :rowspan="item.price_rowspan"
          class="vertical-align-middle"
        ></td>
      </template>
      <template #name="{ item }">
        <td
          v-if="item.price_rowspan > 0"
          :rowspan="item.price_rowspan"
          class="vertical-align-middle"
        >
          <TMessageText
            :value="item.name"
            editable
            removable
            @change="onChangePriceName($event, item)"
            @click-remove="onRemoveRow('prices', item.price_id)"
          />
        </td>
      </template>
      <template #conditions="{ item }">
        <td
          v-if="item.price_rowspan > 0"
          :rowspan="item.price_rowspan"
          class="vertical-align-middle"
        >
          <div class="d-flex flex-column">
            <div v-for="condition in item.conditions" :key="condition.id">
              <SMessagePriceCondition
                :condition="condition"
                editable
                removable
                @changed="reFetchPrices"
                @click-remove="onRemoveRow('conditions', condition.id)"
                noTranslate
                v-if="!['organization', 'type', 'user'].includes(condition.key)"
                class="pt-3 mb-3"
                :groupConditionType="data_key"
              />
            </div>

            <div class="d-flex justify-content-end">
              <TButtonAdd @click="openModalAddPriceCondition(item)" />
            </div>
          </div>
        </td>
      </template>
      <template #organization="{ item }">
        <td
          v-if="item.price_rowspan > 0"
          :rowspan="item.price_rowspan"
          class="vertical-align-middle"
        >
          <SMessageOrganization
            :id="item.organization && item.organization.value"
            editable
            @change="onChangeOrganization($event, item)"
          />
        </td>
      </template>
      <template #timeline_startdate="{ item }">
        <td
          v-if="item.timeline_rowspan > 0"
          :rowspan="item.timeline_rowspan"
          class="vertical-align-middle"
        >
          <TMessageDateTime
            :content="item.timeline_startdate"
            :editable="!!item.timeline_startdate"
            :creatable="!item.timeline_startdate"
            @change="onChangeTimelineStartDate($event, item)"
            :removable="!!item.timeline_startdate"
            @click-remove="onRemoveRow('price_timelines', item.timeline_id)"
          />

          <!-- <TInputDateTime
            :value="input"
            @update:value="setInput"
            :dateOnly="dateOnly"
          /> -->
        </td>
      </template>

      <template #range="{ item }">
        <td>
          <SMessagePriceRange
            :range="item.range"
            :editable="!!(item.range && item.range.length)"
            :removable="!!(item.range && item.range.length)"
            :creatable="
              (!item.range || !item.range.length) && !!item.timeline_id
            "
            @change="onChangePriceRange($event, item)"
            @click-remove="onRemoveRow('price_amounts', item.amount_id)"
            @submit-new-range="onSubmitNewRange($event, item)"
            :unit="getPriceRangeUnit(item)"
          />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageNumber
            :value="item.amount || 0"
            :suffix="getAmountUnit(item)"
            :editable="!!item.amount_id"
            @change="onChangeAmount($event, item)"
          />
        </td>
      </template>
      <!-- <template #_="{ item }">
        <td>
          <TButtonRemove v-if="!item.is_mock" @click="onRemoveAmount(item)" />
        </td>
      </template> -->
    </TTableAdvance>

    <SModalPriceCondition ref="modalPriceCondition" @changed="reFetchPrices" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
    data_key: {
      type: String,
    },
  },
  data() {
    return {
      fields: [
        { key: "_", _style: "min-width: 20px" },
        { key: "name", label: "Name", _style: "min-width:200px" },
        {
          key: "conditions",
          label: "Binding conditions",
          _style: "min-width:200px",
        },
        {
          key: "organization",
          label: "Organization",
          _style: "min-width:200px",
        },
        {
          key: "timeline_startdate",
          label: "Start time",
          _style: "min-width:200px",
        },
        {
          key: "range",
          label: "Conditions to apply",
          _style: "min-width:200px",
        },
        {
          key: "amount",
          label: this.getAmountLabel(),
          _style: "min-width:200px",
        },
      ],
      filter: {},
      showInputTimeLine: false,
    };
  },
  computed: {
    /** Dữ liệu dùng cho table, vì phải tính toán các rowspan nên phải có 1 bước normalize [price_rowspan, timeline_rowspan] */
    data_table() {
      if (!this.prices?.length) return [];

      let r = [];

      this.prices.forEach((row) => {
        (row?.timelines || []).forEach((timeline) => {
          (timeline?.amounts || []).forEach((item, item_idx) => {
            r.push(this.getRowData(row, timeline, item, item_idx));
          });

          // r.push(this.getRowData(row, timeline));
        });

        r.push(this.fakeDataRow(row));
      });

      r = this.lodash.orderBy(
        r,
        ["price_id", "timeline_startdate", "is_mock"],
        ["asc", "desc", "asc"]
      );

      r = r.map((row, idx) => {
        let timeline_rowspan = row.timeline_rowspan;
        let price_rowspan = r.filter((i) => i.price_id == row.price_id)?.length;

        if (!!row?.timeline_id && r[idx - 1]?.timeline_id === row.timeline_id)
          timeline_rowspan = -1;
        if (!!row?.price_id && r[idx - 1]?.price_id === row.price_id)
          price_rowspan = -1;

        return {
          ...row,
          price_rowspan,
          timeline_rowspan,
        };
      });

      return r;
    },
    ...mapGetters({
      freight_charge_types: "warehouse.freight_charge_types.list",
    }),
  },
  methods: {
    /** Fake 1 dòng dữ liệu giả, dùng cho timeline & range_amount */
    fakeDataRow(row) {
      let condition_currency = (row?.conditions || []).find(
        (i) => i.key === "currency"
      )?.value;
      let condition_organization = (row?.conditions || []).find(
        (i) => i.key === "organization"
      );

      return {
        name: row.name,
        timeline_startdate: null,
        timeline_rowspan: 1,
        amount: null,
        range: [],
        amount_id: null,
        timeline_id: null,
        price_id: row.id,
        is_mock: !!row?.timelines?.length,
        currency_id: condition_currency,
        organization: condition_organization,
        conditions: row?.conditions || [],
      };
    },
    /** Tạo 1 dòng dữ liệu */
    getRowData(row, timeline, amount = null, amount_idx = null) {
      let condition_currency = (row?.conditions || []).find(
        (i) => i.key === "currency"
      )?.value;
      let condition_organization = (row?.conditions || []).find(
        (i) => i.key === "organization"
      );

      return {
        name: row.name,
        timeline_startdate: timeline?.start_date,
        timeline_rowspan: timeline?.amounts?.length || 0,
        amount: amount?.amount,
        range:
          !!amount || !!amount_idx
            ? [amount?.range, timeline.amounts[amount_idx + 1]?.range || "∞"]
            : [],
        amount_id: amount?.id,
        timeline_id: timeline.id,
        price_id: row.id,
        is_mock: !amount && !amount_idx && !!timeline?.amounts?.length,
        currency_id: condition_currency,
        organization: condition_organization,
        conditions: row?.conditions || [],
      };
    },
    /** Xoá 1 row, dùng cho cả price, timeline, range_amount */
    onRemoveRow(store, id) {
      if (!id) return;

      this.$tomoni.helper[store]
        .delete(id)
        .then(this.reFetchPrices)
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    /** Cập nhật 1 row, dùng cho cả price, timeline, range_amount */
    onUpdateRow(store, api_type, ...args) {
      this.$tomoni.helper[store][api_type](...args)
        .then(this.reFetchPrices)
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    /** Update timeline startdate */
    onChangeTimelineStartDate(start_date, item) {
      if (item.timeline_id)
        this.onUpdateRow("price_timelines", "update", item.timeline_id, {
          start_date,
        });
      else {
        this.$tomoni.helper.price_timelines
          .create({
            start_date,
            price_id: item.price_id,
          })
          .then((tl) => {
            return this.onUpdateRow("price_amounts", "create", {
              range: 0,
              price_timeline_id: tl.data.id,
              amount: 0,
            });
          });
      }
    },
    /** Update range */
    onChangePriceRange(new_range, item) {
      if (item.amount_id)
        this.onUpdateRow("price_amounts", "update", item.amount_id, {
          range: new_range,
        });
      else
        this.onUpdateRow("price_amounts", "create", {
          range: new_range,
          price_timeline_id: item.timeline_id,
          amount: 0,
        });
    },
    /** Update amount */
    onChangeAmount(new_amount, item) {
      this.onUpdateRow("price_amounts", "update", item.amount_id, {
        amount: new_amount,
      });
    },
    /** Update price name */
    onChangePriceName(name, item) {
      this.onUpdateRow("prices", "update", item.price_id, { name });
    },
    /** Update organization */
    onChangeOrganization(selected_organization_id, item) {
      if (item?.organization?.id) {
        if (selected_organization_id)
          this.onUpdateRow("conditions", "update", item.organization.id, {
            value: selected_organization_id,
          });
        else this.onUpdateRow("conditions", "delete", item.organization.id);
      } else
        this.onUpdateRow("conditions", "create", {
          price_id: item.price_id,
          value: selected_organization_id,
          key: "organization",
        });
    },
    /** re-fetch dữ liệu */
    reFetchPrices() {
      this.$store.dispatch("helper.prices.fetch");
      // this.$store.dispatch("helper.prices.fetch", this.fetch_query);
    },
    /** Thêm mới condition cho 1 row */
    openModalAddPriceCondition(row) {
      this.$refs.modalPriceCondition.open(
        {
          price_id: row.price_id,
        },
        this.data_key
      );
    },
    onSubmitNewRange(new_range, item) {
      this.onUpdateRow("price_amounts", "create", {
        range: new_range,
        price_timeline_id: item.timeline_id,
        amount: 0,
      });
    },
    getPriceRangeUnit(row) {
      if (this.data_key === "shipping-fee") {
        let condition_unit = row.conditions.find((i) => i.key === "unit");

        return this.freight_charge_types.find(i => i.id === condition_unit?.value)?.unit || 'kg';
      }

      if (this.data_key === "exchange-rates") {
        let fromExrUnit = row.conditions.find((i) => i.key === "from");

        if (fromExrUnit) return this.lodash.symbolCurrency(fromExrUnit.value);
        else return "";
      }

      let currencyUnit = row.conditions.find((i) => i.key === "currency");

      if (currencyUnit) return this.lodash.symbolCurrency(currencyUnit.value);

      return "";
    },
    getAmountLabel() {
      if (["insurance-fee", "special-goods-fee", "buying-agent-fee"].includes(this.data_key))
        return "Percent";

      return "Amount";
    },
    getAmountUnit(row) {
      if (["insurance-fee", "special-goods-fee", "buying-agent-fee"].includes(this.data_key))
        return "%";

      if (this.data_key === "exchange-rates") {
        let toExrUnit = row.conditions.find((i) => i.key === "to");

        if (toExrUnit) return this.lodash.symbolCurrency(toExrUnit.value);
        else return "";
      }

      let currencyUnit = row.conditions.find((i) => i.key === "currency");

      if (currencyUnit) return this.lodash.symbolCurrency(currencyUnit.value);

      return "";
    },
  },
};
</script>
