var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex flex-wrap align-items-center"},[_c('TMessage',{staticClass:"ml-2",attrs:{"content":_vm.user.id,"bold":"","noTranslate":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"ml-auto"},[_c('SButtonResetPassword',{staticClass:"ml-auto",attrs:{"email":_vm.user.email}})],1)],1),_c('CCardBody',{staticClass:"d-flex flex-wrap"},[_c('CCol',{attrs:{"col":"12","lg":"12","xl":"12"}},[_c('CRow',[_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 1 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Name","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilUser"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.user.name,"editable":!_vm.loading},on:{"change":function($event){return _vm.$store.dispatch('auth.users.detail.update', {
                name: $event,
              })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 2 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Email","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilMail"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.user.email,"editable":!_vm.loading},on:{"change":function($event){return _vm.$store.dispatch('auth.users.detail.update', {
                email: $event,
              })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 4 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Tel","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilPhone"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.user.tel,"editable":!_vm.loading},on:{"change":function($event){return _vm.$store.dispatch('auth.users.detail.update', {
                tel: $event,
              })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 5 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Role","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilPeople"}})]},proxy:true}])}),(_vm.user.role)?_c('SMessageRole',{staticClass:"mt-2",attrs:{"editable":!_vm.loading,"id":_vm.user.role_id,"content":_vm.user.role.name},on:{"change":function($event){_vm.$store
                .dispatch('auth.users.detail.update', {
                  role_id: $event,
                })
                .then(() => {
                  _vm.$router.push({ path: _vm.user.id });
                })}}}):_vm._e()],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 5 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Organization","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilTimeline"}})]},proxy:true}])}),_c('SMessageOrganization',{staticClass:"mt-2",attrs:{"editable":!_vm.loading,"id":_vm.user.organization_id},on:{"change":function($event){return _vm.$store.dispatch('auth.users.detail.update', {
                organization_id: $event,
              })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 3 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Created at","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilCalendar"}})]},proxy:true}])}),_c('TMessageDateTime',{staticClass:"mt-2",attrs:{"content":_vm.user.created_at}})],1),_c('CCol',{staticClass:"my-2",attrs:{"lg":{ size: 4, order: 6 },"md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Latest update","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"my-auto mr-2",attrs:{"name":"cilCalendarCheck"}})]},proxy:true}])}),_c('TMessageDateTime',{staticClass:"mt-2",attrs:{"content":_vm.user.updated_at}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }