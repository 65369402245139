<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Address" bold />
      <TSpinner :loading="loading"/>
      <TButtonAdd @click="showModalAddress = true" class="ml-auto" variant="outline" />
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol v-for="address in list" :key="address.id" col="12" xl="6">
          <SCardAddress :address="address" :loading="loading" />
        </CCol>
        <SModalAddress
          :show.sync="showModalAddress"
          @created="showModalAddress = false"
          :userId="id"
        />
      </CRow>
      <CCol v-show="paginate.last > 1" col="12" class="mt-2">
        <TPagination store="auth.addresses" class="mt-4" />
      </CCol>
      <TMessageNoData v-if="list.length === 0" />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModalAddress: false,
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("auth.addresses.push-query", {
        "filter[user_id]": `${this.id}`,
      });
    }
  },
  computed: {
    ...mapGetters({
      list: "auth.addresses.list",
      loading: "auth.addresses.fetching",
      paginate: "auth.addresses.paginate",
    }),
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

