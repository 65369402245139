<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Authorization" bold />
      <TSpinner :loading="fetching"/>
      <TSelect
        class="ml-auto mb-0"
        :options="type"
        :value="typeSelected"
        @select="select"
        style="width: 220px"
      />
    </CCardHeader>
    <CCardBody class="mb-2 w-100 mx-auto" style="max-width: 800px">
      <Permission
        v-if="typeSelected.id === 'permissions_role'"
        :key="keyRole"
        :objectId="user.role_id"
        store="auth.permissions_role"
        readonly
      />
      <Permission
        v-if="typeSelected.id === 'permissions'"
        :key="key"
        :objectId="id"
        store="auth.permissions"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import Permission from "../../components/Permissions.vue";
import { mapGetters } from "vuex";
export default {
  components: { Permission },
  data() {
    return {
      key: "key",
      keyRole: "keyRole",
      type: [
        { id: "permissions", name: this.$t("Function") },
        { id: "permissions_role", name: this.$t("Role") },
      ],
      typeSelected: { id: "permissions", name: this.$t("Function") },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth.users.detail",
      fetching: "auth.users.detail.fetching"
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    reset() {
      if (!this.lodash.isEmpty(this.query)) {
        this.$store.commit("auth.resources.reset-query");
        this.key = this.lodash.random(10000, 100000);
        this.keyRole = this.lodash.random(10000, 100000);
      }
    },
    select(type) {
      this.reset();
      this.typeSelected = type;
    },
  },
};
</script>
